import React from "react";
import Layout from "../components/Layout";
import PageTitle from "../components/PageTitle";
import Card from "../components/Card";
import {
  Grid,
  createStyles,
  WithStyles,
  withStyles,
  Typography,
  Link,
} from "@material-ui/core";
import Address from "../components/Address";

interface PricesProps extends WithStyles<typeof styles> {}

const Prices = (props: PricesProps) => {
  const { classes } = props;
  return (
    <Layout>
      <PageTitle text="Prices" />
      <Grid container className={classes.flex}>
        <Grid item xs={12} md={12}>
          <Card withPadding={true}>
            <div>
              <Typography color="primary">
                Adult membership is £120 per year, payable in January. The
                membership fee can be prorated for new members joining part way
                through the year.
              </Typography>
            </div>
            <br />
            <div>
              <Typography color="primary">
                Colt membership is free, but colts must be accompanied by a an
                adult member at every shoot.
              </Typography>
            </div>
            <br />
            <div className={classes.minHeight}>
              <Typography color="primary">
                <table>
                  <thead className={classes.thead}>
                    <tr>
                      <th>Number of Birds</th>
                      <th className={classes.minWidth}>Type</th>
                      <th>Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>50</td>
                      <td>Member</td>
                      <td>£22</td>
                    </tr>
                    <tr>
                      <td>50</td>
                      <td>Guest</td>
                      <td>£31</td>
                    </tr>
                    <tr>
                      <td>50</td>
                      <td>Colt</td>
                      <td>£14</td>
                    </tr>
                    <tr>
                      <td>70</td>
                      <td>Member</td>
                      <td>£28</td>
                    </tr>
                    <tr>
                      <td>70</td>
                      <td>Guest</td>
                      <td>£37</td>
                    </tr>
                    <tr>
                      <td>70</td>
                      <td>Colt</td>
                      <td>£18</td>
                    </tr>
                  </tbody>
                </table>
              </Typography>
              <br />
            </div>
            <div>
              <Typography color="primary">
                Guests are welcome to shoot at the Phoenix CPC, but must be
                accompanied by a member. Guests can shoot upto a maximum of 4
                times before needing to join as full members as stated in the
                club rules.
              </Typography>
            </div>
          </Card>
        </Grid>
      </Grid>
    </Layout>
  );
};

const styles = createStyles({
  flex: {
    display: "flex",
  },
  minHeight: {
    minHeight: "140px",
    color: "red",
  },
  table: {},
  thead: {
    textAlign: "left",
  },
  minWidth: {
    minWidth: "200px",
  },
});

export default withStyles(styles)(Prices);
