import React from "react";
import {
  createStyles,
  withStyles,
  WithStyles,
  Drawer as MaterialDrawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@material-ui/core";
import MailIcon from "@material-ui/icons/Mail";
import HomeIcon from "@material-ui/icons/Home";
import StarsIcon from "@material-ui/icons/Stars";
import PeopleIcon from "@material-ui/icons/People";
import PersonIcon from "@material-ui/icons/Person";
import GavelIcon from "@material-ui/icons/Gavel";
import PhotoAlbumIcon from "@material-ui/icons/PhotoAlbum";
import CardMembershipIcon from "@material-ui/icons/CardMembership";
import MoneyIcon from "@material-ui/icons/Money";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import GridOnIcon from "@material-ui/icons/GridOn";
import { Link } from "react-router-dom";
import { SvgIconProps } from "@material-ui/core/SvgIcon";

interface DrawerProps extends WithStyles<typeof styles> {
  open: boolean;
  setOpen: (open: boolean) => void;
}

interface MenuItem {
  text: string;
  icon: React.ComponentType<SvgIconProps>;
}

const Drawer: React.FunctionComponent<DrawerProps> = (props: DrawerProps) => {
  const { open, setOpen, classes } = props;

  const menu: MenuItem[] = [
    { text: "HOME", icon: HomeIcon },
    { text: "RESULTS", icon: GridOnIcon },
    { text: "SETUP", icon: PeopleIcon },
    { text: "PHOTOS", icon: PhotoAlbumIcon },
    { text: "MEMBERSHIP", icon: CardMembershipIcon },
    { text: "NEW MEMBERS", icon: CardMembershipIcon },
    { text: "PRICES", icon: MoneyIcon },
    //{ text: "WHATSAPP", icon: WhatsAppIcon },
    { text: "CONTACT", icon: MailIcon },
    { text: "INTRO", icon: StarsIcon },
    { text: "CONSTITUTION", icon: GavelIcon },
    { text: "LOGOUT", icon: PersonIcon },
  ];

  return (
    <MaterialDrawer open={open} onClose={() => setOpen(false)}>
      <div
        tabIndex={0}
        role="button"
        onClick={() => setOpen(false)}
        onKeyDown={() => setOpen(false)}
        className={classes.flexBox}
      >
        <List>
          {menu.map((menuItem: MenuItem, index: number) => (
            <Link
              to={menuItem.text.toLowerCase().replace(" ", "_")}
              className={classes.button}
              key={index}
            >
              <ListItem button key={menuItem.text}>
                <ListItemIcon>
                  <menuItem.icon color="secondary" />
                </ListItemIcon>
                <ListItemText
                  primary={menuItem.text}
                  className={classes.listItemText}
                />
              </ListItem>
            </Link>
          ))}
        </List>
      </div>
    </MaterialDrawer>
  );
};

const styles = createStyles({
  button: {
    textDecoration: "none",
  },
  flexBox: {
    display: "flex",
    flexDirection: "column",
  },
  grow: {
    flexGrow: 1,
  },
  listItemText: {
    color: "#FFFFFF",
  },
});

export default withStyles(styles)(Drawer);
