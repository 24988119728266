import React from "react";
import {
  withStyles,
  Grid,
  WithStyles,
  createStyles,
  Typography,
} from "@material-ui/core";
import { setupTeamConfigs, SetupTeamConfig } from "../data/setupTeams";
import SetupTeam from "./SetupTeam";

interface SetupTeamsProps extends WithStyles<typeof styles> {}

const SetupTeams: React.FunctionComponent<SetupTeamsProps> = (
  props: SetupTeamsProps
) => {
  const { classes } = props;

  return (
    <React.Fragment>
      <div className={classes.notice}>
        <Typography>
          Shoot setups are every first, third and fifth of each Sunday of each
          month. Team members should attend at 9:30 AM.
        </Typography>
        <Typography>
          It's your responsibility as a club member to help clear up the shoot.
        </Typography>
      </div>
      <Grid container spacing={10} className={classes.teams}>
        {setupTeamConfigs
          .sort((a: SetupTeamConfig, b: SetupTeamConfig) =>
            a.team.name < b.team.name ? -1 : a.team.name > b.team.name ? 1 : 0
          )
          .map((setup: SetupTeamConfig) => (
            <div
              className={classes.setupTeam}
              key={`setup_team_${setup.team.name}`}
            >
              <SetupTeam {...setup} />
            </div>
          ))}
      </Grid>
    </React.Fragment>
  );
};

const styles = createStyles({
  notice: { marginBottom: "25px" },
  setupTeam: { padding: "10px" },
  teams: { margin: "0 auto" },
});

export default withStyles(styles)(SetupTeams);
