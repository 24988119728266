import { ShootStat } from "../types/model";;

export const headers: (string | undefined)[] = ["NAME", , , , , "5th Jan", "19th Jan", "2nd Feb", "16th Feb", "2nd Mar", "16th Mar", "6th Apr", "20th Apr", "4th May", "18th May", "1st June", "15th June", "6th July", "20th July", "3rd Aug", "17th Aug", "7th Sept", "21st Sept", "29th Oct", "5th Oct", "19th Oct", "2nd Nov", "16th Nov", "7th Dec", "21stDec"];

export const results: (string | number | undefined)[][] = [
["ALLEN JOHN", "B", 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , , , ]
,["AZZOPARDI MATT", "A", 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , , , ]
,["BARROW DAVID", "B", 51.4, 1, 51, , , , , 51, , , , , , , , , , , , , , , , , , , , ]
,["BEALING DAVID", "A", 52.1, 2, 104, 57, , , , 47, , , , , , , , , , , , , , , , , , , , ]
,["BLUNDELL MARK", "C", 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , , , ]
,["BROWN DAVID", "AA", 75.7, 5, 379, 86, 70, 80, 66, 77, , , , , , , , , , , , , , , , , , , , ]
,["CAMERON DEAN", "A", 55.7, 2, 111, , , , 53, 59, , , , , , , , , , , , , , , , , , , , ]
,["CARR-GOMM MAH", "C", 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , , , ]
,["CHECHOTKIN VLADYSLAV ", "AAA", 73.8, 3, 221, , 63, 84, , 74, , , , , , , , , , , , , , , , , , , , ]
,["COLE BERNARD", "B", 40.5, 3, 121, , 46, , 36, 40, , , , , , , , , , , , , , , , , , , , ]
,["CRAWFORD MARTIN", "C", 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , , , ]
,["CULLINGHAM SAM", "A", 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , , , ]
,["CULLINGHAM TATE", "C", 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , , , ]
,["DAWSON PETER", "A", 43.0, 3, 129, , 41, 36, , 51, , , , , , , , , , , , , , , , , , , , ]
,["DEACON ROBERT", "B", 48.2, 4, 193, 49, 56, 43, 46, , , , , , , , , , , , , , , , , , , , , ]
,["DE SILVA RICKY", "B", 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , , , ]
,["DHIR VARINDER", "A", 58.1, 3, 174, , , 67, 50, 57, , , , , , , , , , , , , , , , , , , , ]
,["DUFFELL ERIC", "A", 55.7, 3, 167, , 54, , 46, 67, , , , , , , , , , , , , , , , , , , , ]
,["DUKE NEIL", "A", 61.4, 2, 123, 67, , 56, , , , , , , , , , , , , , , , , , , , , , ]
,["DYER PAUL", "B", 42.9, 3, 129, 47, 34, 47, , , , , , , , , , , , , , , , , , , , , , ]
,["ESCOTT PAUL", "A", 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , , , ]
,["ESCOTT RICHARD", "A", 50.4, 4, 201, 60, 40, , 47, 54, , , , , , , , , , , , , , , , , , , , ]
,["ESCOTT TIM", "AA", 67.6, 3, 203, 70, 63, , , 70, , , , , , , , , , , , , , , , , , , , ]
,["FARAZAMAND A", "C", 67.1, 1, 67, 67, , , , , , , , , , , , , , , , , , , , , , , , ]
,["FARAZAMAND J", "B", 60.5, 3, 181, 73, , 44, , 64, , , , , , , , , , , , , , , , , , , , ]
,["HEALEY LES", "AAA", 72.9, 1, 73, , , , , 73, , , , , , , , , , , , , , , , , , , , ]
,["HIBBERT PAUL", "AAA", 64.3, 2, 129, , , 63, 66, , , , , , , , , , , , , , , , , , , , , ]
,["HOLLOWAY NICK", "B", 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , , , ]
,["HOOLEY PETER", "B", 41.4, 1, 41, , , , , 41, , , , , , , , , , , , , , , , , , , , ]
,["HEPWARTH CHRIS", "C", 41.0, 3, 123, 40, , , 39, 44, , , , , , , , , , , , , , , , , , , , ]
,["JACKSON COLIN", "B", 31.0, 2, 62, , , 34, , 28, , , , , , , , , , , , , , , , , , , , ]
,["JAY CHRIS", "AA", 67.6, 3, 203, 64, 69, , , 70, , , , , , , , , , , , , , , , , , , , ]
,["MICHAEL JOHNSON", "B", 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , , , ]
,["KING JONATHAN", "A", 55.7, 1, 56, 56, , , , , , , , , , , , , , , , , , , , , , , , ]
,["MANUEL RAYNOR", "AA", 55.7, 2, 111, , 57, , , 54, , , , , , , , , , , , , , , , , , , , ]
,["MARCHETTI ALAN", "C", 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , , , ]
,["MORGAN-RUSSELL TIM", "B", 60.0, 2, 120, , 73, 47, , , , , , , , , , , , , , , , , , , , , , ]
,["NICHOLSON GREG", "NM", 47.1, 1, 47, , , 47, , , , , , , , , , , , , , , , , , , , , , ]
,["O'GORMAN ROY", "B", 57.1, 1, 57, , , , , 57, , , , , , , , , , , , , , , , , , , , ]
,["PAYNE BARRY", "B", 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , , , ]
,["PRENTICE ROBERT", "A", 55.7, 2, 111, 67, 44, , , , , , , , , , , , , , , , , , , , , , , ]
,["SEWELL WAYNE", "A", 62.1, 2, 124, , 61, , 63, , , , , , , , , , , , , , , , , , , , , ]
,["SHARP RUSSELL", "A", 37.1, 2, 74, , 40, , 34, , , , , , , , , , , , , , , , , , , , , ]
,["SMALLRIDGE ROSS", "B", 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , , , ]
,["SMALLDRIDGE STEVE", "A", 56.7, 3, 170, 64, 57, , 49, , , , , , , , , , , , , , , , , , , , , ]
,["SMITH LEE", "NM", 84.3, 1, 84, 84, , , , , , , , , , , , , , , , , , , , , , , , ]
,["SOUTHALL IAN", "C", 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , , , ]
,["STANLEY WARWICK ", "C", 32.9, 3, 99, , , 41, 27, 30, , , , , , , , , , , , , , , , , , , , ]
,["SULLIVAN MARTIN", "AA", 56.7, 3, 170, 59, , , 50, 61, , , , , , , , , , , , , , , , , , , , ]
,["THOMAS DUNCAN", "A", 50.3, 5, 251, 54, 39, 67, 37, 54, , , , , , , , , , , , , , , , , , , , ]
,["TRUSTHAM MATT", "B", 61.4, 1, 61, 61, , , , , , , , , , , , , , , , , , , , , , , , ]
,["TRUSTHAM SIMON", "NM", 46.4, 2, 93, 47, , , , 46, , , , , , , , , , , , , , , , , , , , ]
,["TURNER MICHAEL", "C", 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , , , ]
,["VAUGHAN PAYNE R", "C", 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , , , ]
,["VOWLER ALAN", "C", 34.6, 4, 139, 36, 30, 41, 31, , , , , , , , , , , , , , , , , , , , , ]
,["WESTON DAVE", "A", 55.0, 2, 110, , 60, , 50, , , , , , , , , , , , , , , , , , , , , ]
,["WESTON JADEN", "AA", 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , , , ]
,["WHITE ROGER", "B", 41.0, 4, 164, , 44, 44, 30, 46, , , , , , , , , , , , , , , , , , , , ]
,];

export const shootStats: ShootStat[] = [
{"numberOfEntrants": 20, "average": 60.4, "high": 86.0, "low": 36.0},
{"numberOfEntrants": 20, "average": 52.0, "high": 73.0, "low": 30.0},
{"numberOfEntrants": 16, "average": 52.6, "high": 84.0, "low": 34.0},
{"numberOfEntrants": 18, "average": 45.6, "high": 66.0, "low": 27.0},
{"numberOfEntrants": 24, "average": 54.8, "high": 77.0, "low": 28.0},
];
