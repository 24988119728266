import React from "react";
import Layout from "../components/Layout";
import Hero from "../components/Hero";
import Card from "../components/Card";
import homeImg from "../images/home.jpg";

interface HomeProps {}

const Home = (props: HomeProps) => {
  return (
    <Layout>
      <Card>
        <img src={homeImg} alt="Home" height="500px" />
      </Card>
      <Hero
        title={"Welcome to The Phoenix Clay Pigeon Club"}
        messages={[
          "We are a small friendly sporting clay club, with shoots on the first, third and 5th Sundays of every month.",
          "The Phoenix is located in East Horsley, near Cobham in Surrey.",
          "We are a private members club, guests (accompanied by members) and new members are welcome. To become a member please contact us.",
          "Tea, coffee, cold drinks, hot and cold food is available from 9:30. ",
        ]}
      />
    </Layout>
  );
};

export default Home;
